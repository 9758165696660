"use client";

import {
  Cached as CachedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  AddPhotoAlternateOutlined as NewShadingIcon,
	AutoStoriesRounded as GuideIcon,
} from "@mui/icons-material";
import {
  Button,
  InputLabel,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
	useMediaQuery
} from "@mui/material";
import "axios";
import { AxiosError } from "axios";
import React, { useContext, useState } from "react";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { Panel, PanelGroup } from "react-resizable-panels";

import { ImageFormat, zipShading } from "#libs/ImageExports";
import Project from "#libs/Project";
import { downloadShading, getProgress, processCachedTiff, startRequest } from "#libs/apis/backend";
import { MAX_DEMO_RENDERS } from "#libs/constants";
import { COOKIE_USER_DEMO_RENDERS } from "#libs/sessionStorageKeys";
import { ImageRender, ProcessingSettings, Render } from "#libs/types";
import { User } from "#libs/user";
import { getCookie, setCookie } from "#libs/utils";
import { HELP_PAGE } from "App";

import { CreditsContext, ProjectContext } from "#components/Contexts";
import MapDisplay from "#components/MapDisplay";
import MapProperties from "#components/MapProperties/MapProperties";
import { LabelledProgressSpinner } from "#components/ProgressStatus";
import ProjectBar from "#components/ProjectBar";
import NavigationTileset, {
  CreditsTile,
  GettingStartedTile,
  NewShadingTile,
  SelectAreaTile,
  ShadingsTile,
  TryDemoTile,
  UserGuideTile
} from "#components/Tileset";
import {
	InsufficientFundsModal,
	MaxDemoRendersModal,
	NewShadingModal,
	ShadingCreationModal,
} from "#components/modals";

import "#styles/glass";
import "#styles/pages/EditMapPage";


/**
 * Display title for the shading project.
 * FIXME: currently hacky way to center title, should look at more consistent manner to centering
 * @param shading   The shading project currently displayed
 */
function ProjectTitle({ shading }: {
  shading?: Project
}) {
	const isTitleVisible = useMediaQuery('(min-width:1000px)');

  return (
    <section
			style={{
				display: 'flex',
				textAlign: 'center',
				position: 'absolute',
				flexDirection: 'column',
				width: '100%',
				translate: '1% 13px',
				visibility: isTitleVisible ? "visible" : "hidden",
			}}>
      <h6 className="property-heading">{shading?.projectName}</h6>
    </section>
  );
}


/**
 * Button for displaying the create shading dialog.
 * @param disabled  Allows clicking on the create shading button.
 */
function CreateShadingButton({ createShadingCallback, disabled: isDisabled, credits: currentCredits }: {
  createShadingCallback: (arg0: string) => void,
  disabled: boolean,
  credits: number,
}) {
  const auth = useAuthUser();
  const authKey = auth()?.authKey;

  const [showShadingModal, setShowShadingModal] = useState<boolean>(false);
  const [showCreditsModal, setShowCreditsModal] = useState<boolean>(false);

  const onClick = () => {
    if (currentCredits > 0) {
      setShowShadingModal(true);
    }
    else {
      setShowCreditsModal(true);
    }
  };

  const closeShadingModal = () => setShowShadingModal(false);
  const closeCreditsModal = () => setShowCreditsModal(false);

  const createNewShading = (newShadingName: string) => {
    closeShadingModal();
    createShadingCallback(newShadingName);
  }

  return <>
    <Tooltip title="Create new shading" placement="bottom-start">
      <Button
        onClick={onClick}
        disabled={!authKey && isDisabled}
        className="icon-button m-2"
        variant="outlined"
        color="primary" >
        <NewShadingIcon />
      </Button>
    </Tooltip>
    <NewShadingModal open={showShadingModal} onConfirm={createNewShading} onCancel={closeShadingModal} />
    <InsufficientFundsModal isOpen={showCreditsModal} onCancel={closeCreditsModal} />
  </>
}


/**
 * Drop down menu for available shading download formats.
 */
function DownloadFormatMenu({ anchorEl, isOpen, handleClose, handleMenuItemClick }: {
  anchorEl: HTMLElement | null,
  isOpen: boolean,
  handleClose: () => void,
  handleMenuItemClick: (format: ImageFormat) => void,
}) {
  const imageFormatItems: JSX.Element[] = (
		Object.keys(ImageFormat) as Array<ImageFormat>
	).map((format: ImageFormat) => (
		<MenuItem onClick={() => handleMenuItemClick(format)}>
			{ImageFormat[format as keyof typeof ImageFormat]}
		</MenuItem>
	));

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
    >
      <InputLabel sx={{ paddingX: 2, opacity: 0.5 }}>Download Shading</InputLabel>
      {imageFormatItems}
    </Menu>
  );
}


/**
 * Download selection for the shading render.
 * @param shading   The shading render to be downloaded.
 * @param filename  Name of the downloaded file (based upon the shading project name).
 * @param disabled  Allows clicking on the download button.
 */
function DownloadShadingButton({ shading, filename, disabled: isDisabled }: {
  shading: ImageRender
  filename: string
  disabled: boolean
}) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const openMenu = (e: React.MouseEvent<HTMLElement>) => { setAnchorEl(e.currentTarget); };
  const closeMenu = () => { setAnchorEl(null); };

	const auth = useAuthUser();
  const authData = auth();
	const user = { authKey: authData?.authKey, email: authData?.email, name: authData?.name };
	const project = useContext(ProjectContext);

  const downloadShadingWithFormat = async (filetype: ImageFormat) => {
    closeMenu();
		const render: Render = await downloadShading(user, project, filetype);
		zipShading(render, filename);
  };

  return <>
    <Tooltip title="Download shading" placement="bottom-start">
      <Button
        onClick={openMenu}
        disabled={isDisabled}
        className="icon-button m-2"
        color="primary"
        variant="outlined" >
        <FileDownloadOutlinedIcon />
      </Button>
    </Tooltip>
    <DownloadFormatMenu
      anchorEl={anchorEl}
      isOpen={isMenuOpen}
      handleClose={closeMenu}
      handleMenuItemClick={downloadShadingWithFormat} />
  </>
}


/**
 * Download selection for the shading render.
 * @param shading   The shading render to be downloaded.
 * @param filename  Name of the downloaded file (based upon the shading project name).
 * @param disabled  Allows clicking on the download button.
 */
function ShowGuideButton({ disabled: isDisabled }: {
  disabled: boolean
}) {
  const showGuide = () => { window.open(HELP_PAGE, '_blank', 'noopener,noreferrer'); };

  return <>
    <Tooltip title="See user guide" placement="bottom-start">
      <Button
        onClick={showGuide}
        disabled={isDisabled}
        className="icon-button m-2"
        color="primary"
        variant="outlined" >
        <GuideIcon />
      </Button>
    </Tooltip>
  </>
}


/**
 * Error message modal
 * @param { isOpen, errorMessage, onCancel }
 * @returns  Element
 */
function RenderErrorModal({ open: isOpen, errorMessage, onCancel }: {
  open: boolean;
  errorMessage: string | null;
  onCancel: () => void;
}) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="popup">
        <h2>Render Failed</h2>
        <p>{errorMessage}</p>
        <p>Please try again.</p>
        <div>
          <button className="no-button" onClick={onCancel}>OK</button>
        </div>
      </div>
    </div>
  );
}


/**
 * Adjust shading settings for render
 * @param setSettings
 * @returns
 */
function ShadingAdjustmentsPanel({ setSettings, disabled }: {
  setSettings: React.Dispatch<ProcessingSettings>
  disabled: boolean
}) {
  return (
    <div className="settings-panel glass--dark page-content" style={{ width: "300px", overflowY: 'clip' }}>
      <Stack className="map-panel" spacing={0.5} sx={{ whiteSpace: "nowrap" }}>
        <MapProperties callback={setSettings} disabled={disabled} />
      </Stack>
    </div>
  );
}


/**
 * Edits map page
 */
function EditMapPage(): JSX.Element {
  const auth = useAuthUser();
  const user = auth() as User | null;
  const isAuthenticated = useIsAuthenticated();

  const project = useContext<Project>(ProjectContext);
  const { userCredits, updatePageCredits } = useContext(CreditsContext);

  const [settings, setSettings] = useState(project.settings);
  const [hasUpdatedProject, setHasUpdatedProject] = useState(false);
  const [renderUrl, setRenderUrl] = useState<ImageRender>({
    imageSrc: "",
    imageHash: Date.now(),
    geoTiff: "",
    png: "",
    world: "",
    projection: "",
  });
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [isRendering, setRendering] = useState<boolean>(false);
  const [hasMapData, setHasMapData] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showRenderErrorModal, setShowRenderErrorModal] = useState<boolean>(false);
  const [showMaxDemoRendersModal, setShowMaxDemoRendersModal] = useState<boolean>(false);
  const [showShadingCreationModal, setShowShadingCreationModal] = useState<boolean>(project.mapId.length > 0 && !project.bounds);

  const isControlsDisabled = (!isAuthenticated() && !project.isDemo) || !hasMapData || isDownloading || isRendering;
  const isCreateShadingDisabled = !isAuthenticated() || isDownloading || isRendering;
  const canDownloadShading = !(isControlsDisabled || renderUrl.imageSrc === "" || renderUrl.imageSrc === "error" || !renderUrl.imageSrc)

  const canRerenderShading = hasMapData && hasUpdatedProject && !isRendering && !isControlsDisabled;
  const isShadingVisible = (user || project.isDemo) && (renderUrl && renderUrl.imageSrc.length > 0);

  const shadingFilename = project.isDemo ? "Eduard Cloud Demo" : project.projectName;

  /**
   * Element for displaying new project option dropzone
   */
  function NavigationTiles() {
    const project = useContext(ProjectContext);
    const isAuthenticated = useIsAuthenticated();

    const tiles = (!isAuthenticated())
      ? [
        <GettingStartedTile />,
        <TryDemoTile />,
      ] : [
        !project.mapId && <NewShadingTile credits={userCredits} createShadingCallback={handleNewProject} />,
        project.mapId && <SelectAreaTile />,
        <ShadingsTile />,
        <CreditsTile />,
        <UserGuideTile />,
      ];

    return (
      <NavigationTileset rowSize={2}>
        {tiles}
      </NavigationTileset>
    );
  }

  // Call the renderer when the bounds change (first time render)
  React.useEffect(() => {
    if (project.bounds && project.bounds.north || project.isDemo) {
      setHasMapData(true);
      renderShading();
    }

		// We check to display the "Select Area" modal if we load a shading with no defined bounds
		if (isAuthenticated() && !project.isDemo && project.mapId &&
				(project.bounds == null || project.bounds.north == null)
		) {
			setShowShadingCreationModal(true);
		}

    window.addEventListener("dataLoaded", handleDemoLoaded);
  }, []);

  // Checks for updates to project settings
  React.useEffect(() => {
    const hasUpdatedProperties = hasMapData && renderUrl.imageSrc && !hasUpdatedProject;
    if (hasUpdatedProperties) {
      setHasUpdatedProject(true);
    }
  }, [project.settings]);

  // Check for render progress percentage updates when a render is triggered
  React.useEffect(() => {
    if (!requestId || !isRendering)
      return;

    // Start a timer to request an update for the progress every 100ms
    const interval = setInterval(async () => {
      try {
        const currentProgress = await getProgress(requestId);
        setProgress(currentProgress);

        // If the render is complete, delete the timer
        if (currentProgress >= 100) {
          clearInterval(interval);
        }
      } catch (error) {
        console.error("Error fetching progress:", error);
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [requestId]);

  const handleCloseMaxDemoRenders = () => setShowMaxDemoRendersModal(false);

  /* Callback function to revert to a new project */
  const handleNewProject = (newShadingName: string) => {
    setSettings(project.settings);
    setRenderUrl({
      imageSrc: "",
      imageHash: Date.now(),
      geoTiff: "",
      png: "",
      world: "",
      projection: "",
    });
    setDownloading(false);
    setRendering(false);
    setHasMapData(false);
    setRequestId("");
    setProgress(0);
    setHasUpdatedProject(false);

		// Display a popup modal to select an area for the shading
		setShowShadingCreationModal(true);
  }

  /**
   * Function to bypass data download checks if demo data is selected
   */
  const handleDemoLoaded = () => {
    if (project.isDemo) {
      let user_demo_render_count;
      try {
        user_demo_render_count = parseInt(getCookie(COOKIE_USER_DEMO_RENDERS));
      }
      catch {
        user_demo_render_count = 0;
        setCookie(COOKIE_USER_DEMO_RENDERS, user_demo_render_count, 1);
      }

      if (user_demo_render_count < MAX_DEMO_RENDERS) {
        setHasMapData(true);
        renderShading();
        setCookie(COOKIE_USER_DEMO_RENDERS, user_demo_render_count + 1, 1);
      }
      else {
        setShowMaxDemoRendersModal(true);
        setHasMapData(false);
      }
    }
  };

  /**
   * Function to handle the render image response from the back-end
   * @param response_obj
   */
  async function handleRenderResponse(response_obj: any) {
    // If the response images and data is valid, update the front-end values and display the image
		// FIXME: Replace this with the Render class, the image encoding should also occur server side
    const responseJpg = `data:image/jpg;base64,${response_obj.jpg}`;
    const responsePng = `data:image/png;base64,${response_obj.png}`;
    const responseTiff = `data:image/tiff;base64,${response_obj.geoTiff}`;
    const responseWorld = `data:text/plain;base64,${response_obj.world}`;
    const responseProjection = `data:text/plain;base64,${response_obj.projection}`;

    setRenderUrl({
      imageSrc: responseJpg,
      imageHash: Date.now(),
      geoTiff: responseTiff,
      png: responsePng,
      world: responseWorld,
      projection: responseProjection,
    });
  }

  /**
   * Function to handle render fail.
   * @param e Error
   */
  const handleRenderFail = (e: AxiosError) => {
    setProgress(0);
    setRendering(false);
    setHasMapData(false);
    setErrorMessage(`Error ${e.status}: ${e.message}`);
    setShowRenderErrorModal(true);
  }

  /* Callback function once render has completed */
  function onFinishedRender() {
    setRendering(false);
    setHasUpdatedProject(false);
    setProgress(0);
  }

  /* Initiates and performs the render shading process */
  const renderShading = async () => {
    setRendering(true);

    await startRequest()
      .then((newRequestId: string) => {
				// We required pass through the request id as React dispatch functions
				// don't immediately propagate their changes.
				setRequestId(newRequestId);
				return newRequestId;
			})
      .then((newRequestId: string) => processCachedTiff(
        project.settings,
        project.isDemo,
        newRequestId,
        project.projection,
        user,
        project.mapId))
      .then(handleRenderResponse)
      .catch(handleRenderFail)
      .finally(onFinishedRender)
  }


  // Interaction helper functions
  const handleOpenRenderErrorModal = () => { setShowRenderErrorModal(true); };
  const handleCloseError = () => { setShowRenderErrorModal(false); };

  const HeaderContent = (
    <section className="d-flex justify-content-end px-2 w-100" >
      <ProjectTitle shading={isAuthenticated() ? project : undefined} />
			<ShowGuideButton
        disabled={false}
      />
      <CreateShadingButton
        createShadingCallback={handleNewProject}
        disabled={isCreateShadingDisabled}
        credits={userCredits}
      />
      <DownloadShadingButton
        shading={renderUrl}
        filename={shadingFilename}
        disabled={!canDownloadShading}
      />
    </section>
  );

  function RerenderButton() {
    return (
      <Button
        onClick={() => {
          if (project.isDemo) {
            handleDemoLoaded();
          } else {
            renderShading();
          }
        }}
        disabled={isControlsDisabled}
        className="refresh-button"
        variant="outlined"
        color="primary"
        size="large">
        <CachedIcon className="refresh-button__icon" />
				Update Shading
      </Button>
    );
  }

  const ContentDisplay = () => (
    <>
      {isRendering &&
        <div style={{ position: 'absolute', zIndex: 10 }}>
          <LabelledProgressSpinner text="Rendering" value={progress} />
        </div>}
      {canRerenderShading &&
        <RerenderButton />}
      {isShadingVisible || isRendering
        ? isShadingVisible && <MapDisplay renderUrl={renderUrl} />
        : <NavigationTiles />}
    </>
  );

  return <>
    <ProjectBar headerContent={HeaderContent}>
      <PanelGroup autoSaveId="conditional" direction="horizontal" className="background" >
        <Panel
					id="left"
					defaultSize={80}
					className="page-content"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						minWidth: "fit-content"
					}}>
          <ContentDisplay />
        </Panel>
        <ShadingAdjustmentsPanel setSettings={setSettings} disabled={isControlsDisabled} />
      </PanelGroup>
    </ProjectBar>
    <RenderErrorModal open={showRenderErrorModal} onCancel={handleCloseError} errorMessage={errorMessage} />
    <MaxDemoRendersModal open={showMaxDemoRendersModal} onClose={handleCloseMaxDemoRenders} />
		<ShadingCreationModal open={showShadingCreationModal} onCancel={() => setShowShadingCreationModal(false)}/>
  </>;
}

export default EditMapPage;
