import { Backdrop, Button, Link, TextField } from "@mui/material";
import L from 'leaflet';
import React, { ChangeEvent, useContext, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

import { ProjectContext } from "#components/Contexts";
import DEMDropdown from "#components/MapProperties/DEMDropdown";
import ProjectionDropdown from "#components/MapProperties/ProjectionDropdown";
import MapSelector, { DEFAULT_ZOOM } from "#components/MapSelector";
import { BaseModal, } from "#components/modals";
import { ProgressSpinner } from "#components/ProgressStatus";
import ProjectBar from "#components/ProjectBar";
import {
	getUserApiKey,
	setUserApiKey,
	updateMapSource
} from "#libs/apis/backend";
import { MAX_AREA_SIZE } from "#libs/constants";
import { getAreaSize } from "#libs/geo";
import {
	DEFAULT_ELEVATION_MODEL,
	DEFAULT_PROJECTION,
	ElevationModel,
	Project,
	Projection
} from "#libs/Project";
import { COOKIE_PREVIOUS_AREA_SELECTION } from "#libs/sessionStorageKeys";
import { User } from "#libs/user";
import { getCookie, KeyError, setCookie } from "#libs/utils";
import { EDIT_MAP_PAGE } from "App";

import logo from "#assets/logo.png";
import "#styles/pages/DownloadMap";

const DEFAULT_BOUNDS = new L.LatLngBounds([[46.0, 8.6], [46.4, 9.2]]);  // Eduard starting position


/***
 * Greyed out backdrop preventing action whilst downloading from OpenTopography
 * @param isActive: Flag if download flag is active
 */
function LoadingAreaBackdrop({ isActive }: { isActive: boolean }) {
	return (
		<Backdrop
			open={isActive}
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} >
			<ProgressSpinner text="Loading" />
		</Backdrop>
	);
}


/**
 * Check non-empty api key is set.
 * @param apiKey The provided api key to test.
 * @returns Truthy value of valid key.
 */
function isValidApiKey(apiKey?: string): boolean {
	return apiKey !== "" && apiKey != undefined;
}


/***
 * Dialog box for requesting OpenTopography API Key.
 */
function ApiKeyModal({ open: isOpen, setOpen, updateApiKey }: {
	open: boolean,
	setOpen: React.Dispatch<boolean>
	updateApiKey: () => void
}) {
	const navigate = useNavigate();
	const auth = useAuthUser();

	const user = auth() as User | null;
	const [apiKey, setApiKey] = useState<string>("");

	const handleClose = () => setOpen(false);
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value);
	// Close the dialogue box and return to previous page
	const handleCancel = () => {
		handleClose();
		navigate(EDIT_MAP_PAGE);
	};

	// Update the API key
	const handleSubmit = async () => {
		if (user == null)
			throw new Error("User is not authenticated");

		await setUserApiKey(user, apiKey)
			.then(updateApiKey)
			.then(handleClose)
			.catch(console.error);
	};

	return (
		<BaseModal
			open={isOpen}
			heading="OpenTopography API Key"
			neutralButton={{ title: "Cancel", onClick: handleCancel }}
			yesButton={{ title: "Submit", onClick: handleSubmit }}
			maxWidth="sm" >
			<div>
				<span>An API Key is needed to download grids from OpenTopography.</span>
				<br />
				<Link href="https://opentopography.org" target="_blank">
					Request an API key from OpenTopography.org.
				</Link>
			</div>
			<TextField
				autoFocus
				className="api-key-modal__input-label"
				fullWidth
				id="apiKey"
				label="OpenTopography API Key"
				margin="dense"
				onChange={handleChange}
				type="text"
				variant="standard"
			/>
		</BaseModal>
	);
}


function ConfirmAreaModal({ open: isOpen, onConfirm, onCancel }: {
	open: boolean
	onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) {
	return (
		<BaseModal
			open={isOpen}
			heading="Confirm Area"
			yesButton={{ title: "Confirm", onClick: onConfirm }}
			neutralButton={{ title: "Cancel", onClick: onCancel }}
			maxWidth="xs" >
			<div style={{ paddingInline: 30 }}>
				<p>
					The selected area will be final and cannot be changed for this shading.
					Are you sure you want to select this area?
				</p>
			</div>
		</BaseModal>
	);
}


/**
 * OpenTopography error reponse modal
 * @param { isOpen, errorMessage, httpCode, onCancel }
 */
function OpenTopographyErrorModal({ isOpen, errorMessage, onCancel }: {
	isOpen: boolean;
	errorMessage: string | null;
	onCancel: () => void;
}) {
	return (
		<BaseModal
			open={isOpen}
			heading="OpenTopography Error"
			noButton={{ title: "OK", onClick: onCancel }} >
			<div style={{ paddingInline: 30, display: "grid" }}>
				<img className="eduard-logo" style={{ justifySelf: "center", marginBottom: 10 }} src={logo} alt="Eduard icon" />
				<p>{errorMessage}</p>
			</div>
		</BaseModal>
	);
}

interface CachedBounds {
	zoom: number,
	bounds: L.LatLngBounds,  // stored as L.LatLngBounds.toBBoxString()
}

function getBoundsOnPageLoad(): CachedBounds {
	try {
		// Unpack the cached L.LatLngBounds string into respective coordinates
		const cookieData = getCookie(COOKIE_PREVIOUS_AREA_SELECTION);
		const cachedBoundsData = JSON.parse(cookieData)
		const [sw_lng, sw_lat, ne_lng, ne_lat] = cachedBoundsData.bounds.split(',').map(Number);

		return {
			zoom: cachedBoundsData.zoom,
			bounds: new L.LatLngBounds([sw_lat, sw_lng], [ne_lat, ne_lng]),
		};
	}
	catch (e) {
		// No cookie found
		if (e instanceof KeyError) {
			return {
				zoom: DEFAULT_ZOOM,
				bounds: DEFAULT_BOUNDS,
			};
		}
		// Erroneous deserialisation
		else {
			throw e;
		}
	}
}

function cacheBoundsAsCookie(bounds: L.LatLngBounds, zoom: number) {
	const COOKIE_DURATION = 0.02;  // Approximately ~30 minutes (in days)
	setCookie(COOKIE_PREVIOUS_AREA_SELECTION, {
		zoom: zoom,
		bounds: bounds.toBBoxString(),
	}, COOKIE_DURATION);
}

/**
 * Download map page
 */
function DownloadMapPage(): JSX.Element {
	const auth = useAuthUser();
	const navigate = useNavigate();

	// Current user data
	const user = auth() as User | null;
	const [apiKey, setApiKey] = useState<string>("");

	// Cached selection area from last session
	const cachedBounds: CachedBounds = getBoundsOnPageLoad();
	const initialZoom = cachedBounds.zoom || DEFAULT_ZOOM;

	// Project settings
	const project = useContext<Project>(ProjectContext);
	const [bounds, setBounds]	=	useState<L.LatLngBounds>(cachedBounds.bounds);
	const [elevationModel, setElevationModel] = useState<ElevationModel>(DEFAULT_ELEVATION_MODEL);
	const [projection, setProjection] =	useState<Projection>(DEFAULT_PROJECTION);

	// Page state
	const [isSavingDEM, setSavingDEM] = useState<boolean>(false);
	const validBoundsSelection = getAreaSize(bounds) < MAX_AREA_SIZE;
	const isDownloadButtonActive = isValidApiKey(apiKey) && validBoundsSelection;

	// Modals
	const [showApiKeyModal, setShowApiKeyModal] = useState<boolean>(false);
	const [showConfirmAreaModal, setShowConfirmAreaModal] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [apiErrorText, setApiErrorText] = useState<string>("");

	const updatePageApiKey = async () => {
		if (user == null)
			throw new Error("User is not authenticated");
		// Request backend api for key or try local storage
		const apiKey = await getUserApiKey(user);

		if (isValidApiKey(apiKey)) {
			setApiKey(apiKey);
			setShowApiKeyModal(false);
		} else {
			setShowApiKeyModal(true);
		}
	};

	const handleUpdateBounds = (zoom: number) => {
		return (bounds: L.LatLngBounds) => {
			setBounds(bounds);
			cacheBoundsAsCookie(bounds, zoom);
		}
	}

	/* Request server to download and save OpenTopography data */
	const handleUpdateMapSource = async () => {
		if (user == null)
			throw new Error("User is not authenticated");

		setSavingDEM(true);

		// Download OpenTopography data and store bounds
		await updateMapSource(user, project.mapId, bounds, elevationModel, projection)
			.then(() => {
				// Update project data and return to the editing workspace
				project.bounds = bounds;
				project.projection = projection;
				project.elevationModel = elevationModel;

				navigate(EDIT_MAP_PAGE);
			})
			.catch(error => {
				// Update and display OpenTopography error modal
				setApiErrorText(error.response.data.responseText);
				setShowErrorModal(true);
			});

		setSavingDEM(false);
	}

	// Update API key and credit balance from database
	React.useEffect(() => {
		updatePageApiKey()
			.catch(console.error)
	}, [apiKey]);

	/* Footer element, contails DEM download properties + Load/Cancel actions. */
	function Footer() {
		return (
			<div className="footer">
				<div className="panel glass--dark">
					<div style={{ display: 'flex', alignItems: 'center', gap: "2rem" }}>
						<div style={{ display: "inline-flex", gap: "2rem" }}>
							<span className="dem-dropdown__width">
								<DEMDropdown
									elevationModel={elevationModel}
									onChange={setElevationModel}
									defaultValue={DEFAULT_ELEVATION_MODEL}
									SelectProps={{
										autoWidth: true,
										MenuProps: {
											slotProps: { paper: { className: "dem-dropdown__width" } },
											anchorOrigin: { horizontal: "right", vertical: "top" },
											transformOrigin: { horizontal: "right", vertical: "bottom" },
										}
									}}
								/>
							</span>
							<span className="projection-dropdown__width">
								<ProjectionDropdown
									projection={projection}
									onChange={setProjection}
									defaultValue={DEFAULT_PROJECTION}
									SelectProps={{
										MenuProps: {
											slotProps: { paper: { className: "footer__projection-dropdown__width" } },
											anchorOrigin: { horizontal: "right", vertical: "top" },
											transformOrigin: { horizontal: "right", vertical: "bottom" },
										}
									}}
								/>
							</span>
						</div>
						<div style={{ display: "inline-flex", gap: "10px", width: 300 }}>
							<Button
								onClick={() => navigate(EDIT_MAP_PAGE)}  // href refreshes page context
								className="neutral-button"
								variant="text"
								size="large"
								fullWidth >
								Cancel
							</Button>
							<Button
								onClick={() => setShowConfirmAreaModal(true)}
								disabled={!isDownloadButtonActive}
								className={isDownloadButtonActive ? "yes-button" : "no-button"}
								variant="text"
								size="large"
								fullWidth >
								{isDownloadButtonActive ? "Load Area" : "Invalid Area"}
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return <>
		<ProjectBar>
			<div className="page-layout">
				<LoadingAreaBackdrop isActive={isSavingDEM} />
				<MapSelector bounds={bounds} handleBounds={handleUpdateBounds} zoom={initialZoom} />
				<Footer />
			</div>
		</ProjectBar>
		<ApiKeyModal open={showApiKeyModal} setOpen={setShowApiKeyModal} updateApiKey={updatePageApiKey} />
		<ConfirmAreaModal
			open={showConfirmAreaModal}
			onConfirm={() => {
				setShowConfirmAreaModal(false);
				handleUpdateMapSource();
			}}
			onCancel={() => setShowConfirmAreaModal(false)}
		/>
		<OpenTopographyErrorModal
			isOpen={showErrorModal}
			errorMessage={apiErrorText}
			onCancel={() => setShowErrorModal(false)}
		/>
	</>
}


export default DownloadMapPage;
