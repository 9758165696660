// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Edit map page
"use client";

import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import React, { ReactNode, useContext, useState } from "react";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

import { CreditsContext, ProjectContext } from "#components/Contexts";
import NavigationTileset, { SelectAreaTile } from "#components/Tileset";
import Project from "#libs/Project";
import { createMap } from "#libs/apis/backend";
import { DEFAULT_SHADING_NAME } from "#libs/constants";
import { User } from "#libs/user";
import { PAYMENT_PAGE } from "App";


interface ButtonInterface {
  title: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

/**
 * The base modal template
 * @param open 	Boolean if the modal is visible.
 * @param heading	The heading text for the modal.
 * @param children	The content of the modal.
 * #FIXME: This should just take ButtonInterfaces[] and place in the dialog actions
 * @param yesButton	The yes button action for the modal (confirm).
 * @param neutralButton	The neutral button action (cancel).
 */
function BaseModal({ open: isOpen, heading, children: modalContent, yesButton, noButton, neutralButton, maxWidth="sm" }: {
  open: boolean,
  heading: string,
  children?: ReactNode,
  yesButton?: ButtonInterface,
  neutralButton?: ButtonInterface,
  noButton?: ButtonInterface,
  maxWidth?: false | Breakpoint
}) {
  return (
    <Dialog open={isOpen} fullWidth maxWidth={maxWidth} PaperProps={{ className: "glass--dark solid-fill" }}>
      <DialogTitle>
        {heading}
        <hr />
      </DialogTitle>
      <DialogContent >
        <div style={{ paddingTop: '5px', height: '100%' }}>
          {modalContent}
        </div>
      </DialogContent>
      <DialogActions>
        {neutralButton &&
          <Button onClick={neutralButton.onClick} className="neutral-button">
            {neutralButton.title}
          </Button>}
        {noButton &&
          <Button onClick={noButton.onClick} className="no-button">
            {noButton.title}
          </Button>}
        {yesButton &&
          <Button onClick={yesButton.onClick} className="yes-button">
            {yesButton.title}
          </Button>}
      </DialogActions>
    </Dialog>
  );
}


/**
 * Create new shading dialog. Gets input name of the newly created shading.
 * @param { isOpen, onConfirm, onCancel }
 */
function NewShadingModal({ open: isOpen, onConfirm: confirmCallback, onCancel }: {
  open: boolean;
  onConfirm: (arg0: string) => void;
  onCancel: () => void;
}) {
  const project = useContext<Project>(ProjectContext);
  const [shadingName, setShadingName] = useState(DEFAULT_SHADING_NAME);

  const { updatePageCredits } = useContext(CreditsContext);

  const auth = useAuthUser();
  const user = auth() as User | null;

  const handleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const newShadingName = (e.target.value.length > 0) ? e.target.value : DEFAULT_SHADING_NAME;
    setShadingName(newShadingName);
  }
  const handleConfirm = async () => {
    if (user == null)
      throw new Error("User must be authenticated to create a new shading");

    try {
      // Save map data
      const mapId = await createMap(shadingName, user);

      // Clear project data upon success
      project.reset();
      project.mapId = mapId.toString();
      project.projectName = shadingName;

      updatePageCredits();

      confirmCallback(shadingName);
    }
    catch (error) {
      console.error("Failed to create new project, please try again later");
    }
  }

  return (
    <BaseModal
      open={isOpen}
      heading="New Shading"
      maxWidth="xs"
      yesButton={{ title: "Create", onClick: handleConfirm }}
      neutralButton={{ title: "Cancel", onClick: onCancel }} >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <span style={{ textWrap: 'nowrap', marginRight: '20px' }}>Name:</span>
        <TextField label="Shading name" defaultValue={shadingName} onBlur={handleChange}
          variant="outlined" required fullWidth size="small" />
      </div>
      <br />
      <p>Creating a new shading will cost one Eduard credit.</p>
    </BaseModal>
  );
}

function ShadingCreationModal({ open: isOpen, onCancel}: {
	open: boolean;
	onCancel: () => void;
}) {
	return (
		<BaseModal
			open={isOpen}
			heading="Elevation Data"
			maxWidth="xs"
			neutralButton={{ title: "Cancel", onClick: onCancel }}>
			<p>Load elevation data for the shading.</p>
			<NavigationTileset rowSize={2}>
				{[<SelectAreaTile />]}
			</NavigationTileset>
		</BaseModal>
	);
}


/**
 * Confirmation modal
 * @param { isOpen, onConfirm, onCancel }
 */
function ConfirmRenderModal({ isOpen, onConfirm, onCancel, userCredits }: {
  isOpen: boolean;
  onConfirm: () => void,
  onCancel: () => void,
  userCredits: number,
}) {

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" PaperProps={{ className: "glass--dark solid-fill" }}>
      {/* <DialogTitle>Insufficient Credits</DialogTitle> */}
      <DialogContent sx={{ overflow: "hidden" }}>
        <h1>Load Area and Render</h1>
        <hr />
        <div style={{ paddingInline: 30, textWrap: 'nowrap' }}>
          <p>Loading this area and rendering a shading will cost one Eduard Credit.</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="neutral-button" onClick={onCancel}>Cancel</Button>
        <Button className="yes-button" onClick={onConfirm}>Load Area</Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Dialog displayed for insuficient credits.
 * @param isOpen 		Boolean for if the modal dialog is visible.
 * @param onCancel 	Callback function called on clicking cancel
 *
 */
function InsufficientFundsModal({ isOpen, onCancel }: {
  isOpen: boolean;
  onCancel: () => void;
}) {
  const navigate = useNavigate();
  const handlePurchaseCredits = () => navigate(PAYMENT_PAGE);

  return (
    <BaseModal
      open={isOpen}
      heading="Insufficient Credits"
      maxWidth="xs"
      neutralButton={{ title: "Cancel", onClick: onCancel }}
      yesButton={{ title: "Purchase Credits", onClick: handlePurchaseCredits }} >
      {/* <h1>Insufficient Credits</h1>
      <hr /> */}
      <div style={{ paddingInline: 30 }}>
        <p>Eduard credits are required to render a shading.</p>
      </div>
    </BaseModal>
  );
}


/**
 * Dialog warning the user reaching the maximum number of demo renders.
 * @param open 			Boolean for if the modal visible.
 * @param onCancel 	Callback function called on clicking close.
 */
function MaxDemoRendersModal({ open: isOpen, onClose }: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <BaseModal
      open={isOpen}
      heading="Maxmimum Demo Shadings"
      neutralButton={{ title: "Close", onClick: onClose }} >
      <p>
        The maximum number of demo shadings has been reached.
        Try again later or sign in with your Google account and purchase shading credits.
      </p>
    </BaseModal>
  );
}


export {
  BaseModal,
  ConfirmRenderModal,
  InsufficientFundsModal,
  MaxDemoRendersModal,
	NewShadingModal,
	ShadingCreationModal,
};

