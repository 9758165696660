// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Session storage for getting started panel appearance

export const CLOSED_GETTING_STARTED = "CLOSED_GETTING_STARTED";
export const CLOSED_RENDER_PAYMENT = "CLOSED_RENDER_PAYMENT";
export const SESSION_API_KEY = "SESSION_API_KEY";

// Stores the number of demo renders in the last day
export const COOKIE_USER_DEMO_RENDERS = "COOKIE_USER_DEMO_RENDERS";
// Stores a BBoxString() of the last selected area + map zoom
// See: https://leafletjs.com/reference.html#latlngbounds
export const COOKIE_PREVIOUS_AREA_SELECTION = "COOKIE_PREVIOUS_AREA_SELECTION";
